import React from "react"
import Layout from "@/components/layout"
import BolsaForm from "@/components/EscolaDePaisForm"

const EscolaDePaisPage = () => {
  return (
    <Layout>
      <BolsaForm />
    </Layout>
  );
};

export default EscolaDePaisPage;
